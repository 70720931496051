<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>
    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data da"
                v-model="filter.data_registrazione_inizio"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data a"
                v-model="filter.data_registrazione_fine"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="treasuries"
                label="Conto"
                :options="treasuries_opt"
                v-model="filter.conto"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="insurer"
                label="Compagnia"
                :options="companies"
                v-model="filter.compagnia"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Descrizione"
                v-model="filter.descrizione"
                placeholder="Inserisci una descrizione"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="cash_references"
                label="Causale"
                :options="cash_references_opt"
                v-model="filter.causale"
                :multiple="true"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="salesmen"
                label="Produttore"
                :options="getSalesmen()"
                v-model="filter.produttore"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXCOGIMO',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <cash-entries-table
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [],
      }"
      :filterName="filterName"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy']"
      sortField="data_registrazione"
    ></cash-entries-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import CashEntriesTable from "@/components/tables/CashEntry.vue";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { today } from "@/utils/dates";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import ExportOptions from "@/components/ExportOptions";

export default {
  mixins: [CustomFiltersMixin, ConfirmModalMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      repository: "cash_entry",
      resource: "movimenti_prima_nota",
      tableRef: "CashEntriesDefaultTableRef",
      filterName: "filterCashEntriesDefault",
      filter: this.initFilter(),
      companies: [],
      treasuries_opt: [],
      cash_references_opt: [],
      customFilter: {
        customByBroker: null,
      },
      fields: [
        {
          key: "conto.codice",
          label: this.getDictionary("Conto"),
          sortable: true,
          sortKey: "byTreasury.display",
        },
        {
          key: "data_registrazione",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("cash_time", "cash_entry"),
          sortable: true,
          sortKey: "data_registrazione",
        },
        {
          key: "id_compagnia",
          label: this.getDictionary("insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
          formatter: (value) => this.retriveInsurer(value),
        },
        {
          key: "descrizione",
          label: this.getDictionary("title", "cash_entry"),
          sortable: true,
          sortKey: "descrizione",
        },
        {
          key: "causale",
          label: "Causale",
          formatter: (value) => `${value.codice} - ${value.descrizione}`,
          sortable: true,
          sortKey: "byCashReference.code",
        },
        {
          key: "produttore.title",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.title",
        },
        {
          key: "entry_in",
          formatter: (value) => toLocaleCurrency(value),
          label: this.getDictionary("entry_in", "cash_entry"),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "entry_out",
          formatter: (value) => toLocaleCurrency(value),
          label: this.getDictionary("entry_out", "cash_entry"),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  components: {
    CashEntriesTable,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    ExportOptions,
  },
  methods: {
    retriveInsurer(value) {
      let insurer_text = "";
      this.companies.forEach((company) => {
        if (company.value === value) insurer_text = company.text;
      });
      return insurer_text;
    },
    initFilter() {
      let init = {
        data_registrazione_inizio: today(),
        data_registrazione_fine: today(),
        produttore: null,
        compagnia: null,
        causale: null,
        conto: null,
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id, item) {
      this.$router.push({
        name: `${this.resource}.edit0`,
        params: { id: `${id}`, item: item },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create0` });
    },
    onDestroy(id, item) {
      if (item.causale.causale_collegata) {
        this.showConfirm({
          yesCallback: () => {
            this.destroy(id);
          },
          noCallback: null,
          title: "Conferma Eliminazione",
          message:
            "Stai per eliminare una registrazione collegata ad un’altra tramite causale automatica. La cancellazione sarà efficace su entrambe le registrazioni. Vuoi Procedere?",
          yesLabel: "ELIMINA",
          noLabel: "ANNULLA",
        });
        return;
      }

      this.destroy(id);
    },
    destroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].sortBy = "id";
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Movimento Contabile Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      // this.$refs[this.tableRef].sortBy = "data_registrazione";
      // this.$refs[this.tableRef].sortAsc = true;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "data_registrazione";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getCashReferences: "cash_references",
      getContiPrimaNota: "allContiPrimaNota",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.companies = this.getInsurers();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
    this.cash_references_opt = [];
    let cashReferences = this.getCashReferences();
    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        reference_type: el.reference_type,
        has_broker: el.has_broker,
        has_company: el.has_company,
        entry_type: el.entry_type,
        value: el.value,
        treasury_id: el.treasury_id,
      });
    });
    this.treasuries_opt = this.getContiPrimaNota();
  },
};
</script>
