<template>
  <b-modal
    id="editDeferredCashEntryModal"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close()"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="pencil"></b-icon>
        <span>Modifica dettaglio</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>
    <div class="mt-2">
      <validation-observer ref="observer1" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="">
          <b-card class="filter">
            <b-row>
              <div class="col-md-6">
                <base-datepicker
                  vid="valuta_da"
                  name="Data Recupero Sospeso"
                  label="Data Recupero Sospeso"
                  v-model="inputVal.data_registrazione"
                  :disabled="true"
                  :readonly="true"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  vid="broker_id"
                  name="produttore"
                  label="Produttore"
                  v-model="inputVal.id_produttore"
                  :options="getSalesmen()"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                  :disabled="true"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  vid="cash_reference_id"
                  name="causale"
                  label="Causale"
                  v-model="inputVal.id_causale_prima_nota"
                  :options="cash_references_opt"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                  :disabled="true"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  vid="title"
                  name="descrizione"
                  label="Descrizione"
                  v-model="inputVal.related_description"
                  placeholder="Inserisci una descrizione"
                  maxlength="256"
                  :rules="{ required: true }"
                  :disabled="true"
                />
              </div>
              <div class="col-md-6">
                <base-currency
                  name="importo"
                  vid="gross"
                  label="Importo"
                  v-model="inputVal.gross"
                  :options="{
                    currency: 'EUR',
                    locale: 'it-IT',
                    precision: 2,
                  }"
                  :rules="{
                    required: true,
                    compare_number: { otherValue: '0', operator: '>' },
                  }"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  vid="treasury_id"
                  name="conto"
                  label="Conto"
                  v-model="inputVal.id_conto_prima_nota"
                  :options="getContiPrimaNota()"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                  :rules="{ required: true }"
                />
              </div>
            </b-row>
          </b-card>
          <div class="py-2 mb-4">
            <b-button
              @click="handleSubmit(save)"
              type="button"
              :disabled="invalid"
              variant="lisaweb"
              size="sm"
            >
              Salva
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseInput from "@/components/form/BaseInput";

export default {
  data() {
    return {
      DEFERRED_CASH_REFERENCE: 4,
      cash_references_opt: [],
    };
  },
  components: {
    BaseCurrency,
    BaseSelect,
    BaseDatepicker,
    BaseInput,
  },
  props: {
    value: null,
    initialGross: Number,
  },
  methods: {
    save() {
      this.$emit("save", this.inputVal);
      this.$bvModal.hide("editDeferredCashEntryModal");
    },
    close() {},
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getSalesmen: "salesmen",
      getContiPrimaNota: "allContiPrimaNota",
    }),
  },
  created() {
    let cashReferences = this.getCashReferences();
    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        reference_type: el.reference_type,
        has_broker: el.has_broker,
        has_company: el.has_company,
        entry_type: el.entry_type,
        value: el.value,
        treasury_id: el.treasury_id,
      });
    });
    this.cash_references_opt = this.cash_references_opt.filter(
      (cash_reference) =>
        cash_reference.reference_type === this.DEFERRED_CASH_REFERENCE
    );
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  overflow-y: visible;
}
</style>
