<template>
  <!-- questo markup contiene le parti generiche. E' qui solo per essere copiato nella destinazione, viene sovrascritto da quello presente nella destinazione -->
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <!-- Main table element -->
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(remainder)="{ item }" v-if="items">
            {{ toLocaleCurrency(getRemainder(item)) }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                :disabled="hasBeenDeposited(row.item)"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id, row.item)"
                class="mt-1 mr-1"
                :disabled="hasBeenDeposited(row.item)"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
          ></b-pagination>
        </div>
      </b-row>
      <b-row>
        <div class="deposited_yes_colour"></div>
        <p class="label">Aperti</p>
        <div class="deposited_no_colour"></div>
        <p class="label">Parzialmente incassati</p>
        <div class="deposited_reduced_colour"></div>
        <p class="label">Chiusi</p>
        <!-- <p class="label">Righe Selezionate: {{ getSelectedRowsLength() }}</p>
        <p class="label">
          Totale da Incassare: {{ toLocaleCurrency(grandTotalDeferred) }}
        </p> -->
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>

              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
// import General from "@/components/accounting/book-entries/details/General.vue";
import General from "@/components/accounting/cash-entries/deposit/details/General";
import Deposited from "@/components/accounting/cash-entries/deposit/details/Deposited";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
export default {
  name: "DepositedEntryDetailsTable",
  extends: template,
  props: {
    depositedStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Deposited", text: "Storico Acconti" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
      dbRowData: null,
      // args: { verb: { name: "acconti", alias: "index" } },
    };
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    rowClass(item) {
      if (!item) return "";
      if (!item.recupero) return "deposited-status-0"; // Aperto
      if (item.importo === item.recupero) return "deposited-status-2"; // Chiuso
      return "deposited-status-1"; // Parzialmente incassato
    },
    hasBeenDeposited(item) {
      if (item?.recupero) return true;
      return false;
    },
    totalDeposited(item) {
      if (!item?.reduced) {
        return false;
      }
      return this.getRemainder(item) === 0;
    },
    partialDeposited(item) {
      if (!item?.reduced) {
        return false;
      }
      return Math.abs(item?.reduced) < item?.gross;
    },
    getRemainder(item) {
      return item?.gross + item?.reduced;
    },
    getDepositedId(item) {
      return item.treasury.code === "SS" && parseFloat(item.gross) < 0
        ? item.id
        : null;
    },
    isLastRow(index) {
      return index === this.items.length - 1;
    },
    hasAbbuoni() {
      let allIds = [];
      let items = this.$parent.$parent.details || [];
      for (const i of items) {
        let id = this.getDepositedId(i);
        if (id) allIds.push(id);
      }
      for (const id of allIds) {
        const found = items.find((e) => e.id === id);
        if (found && found.entry_details) {
          const found2 = found.entry_details.find(
            (e) => e.treasury.code === "AB"
          );
          if (found2) {
            return true;
          }
        }
      }
      return false;
    },
    isAbbuono(item) {
      return item.treasury.code === "AB";
    },
    isRecuperoAcconto(item) {
      // #1008: modifica temporanea in questa table
      return item.treasury.code === "UC";
    },
  },
  // beforeMount() {
  //   this.args = this.$parent.$parent.args;
  // },
  components: {
    BaseIcon,
    General,
    Deposited,
    ButtonGroupTab,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
/*
:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
*/
/** inserisce un gradiente orizzontale nella prima cella della table */
/* ROSSO */
:deep(.deposited-status-0 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(228, 33, 33, 0.3) 100%
  );
}
/* VERDE */
:deep(.deposited-status-1 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(19, 172, 19, 0.4) 100%
  );
}
/* NEUTRO */
:deep(.deposited-status-2 td:nth-child(2)) {
  background: rgb(255, 255, 255);
}
.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_yes_colour {
  width: 15px;
  height: 15px;
  background: rgba(228, 33, 33, 0.3);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_no_colour {
  width: 15px;
  height: 15px;
  background: rgba(19, 172, 19, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_reduced_colour {
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 1);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>
