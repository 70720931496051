<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <deferred-cash-entries
          :fields="fields"
          :repository="repository"
          :resource="resource"
          @edit="onEdit"
          @destroy="onDestroy"
          @parentFetch="fetchParent"
          :filterName="filterName"
          :ref="tableRef"
          :onlyActions="['destroy', 'edit']"
          :filterOn="{
            byRelations: ['byCashEntryHistory'],
          }"
          noInnerWidth
          noPagination
          noInputPaginator
        >
        </deferred-cash-entries>
        <edit-deferred-cash-entry-modal
          @save="onUpdateEntryDetail"
          v-model="item"
        ></edit-deferred-cash-entry-modal>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import EditDeferredCashEntryModal from "@/components/modals/editDeferredCashEntryModal";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import moment from "moment";
moment.locale("it");
import { toLocaleDate } from "@/utils/dates";
import { mapGetters } from "vuex";

export default {
  mixins: [ShowMixin, FormMixin, DetailTabAutofetchTableMixin],
  name: "CashEntryDeferred",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      details: [],
      parent_details: [],
      detail: null,
      item: null,
      initial_gross: null,
      filterName: "filterModalCashEntryDeferredDetail",
      repository: "cash_entry",
      resource: "movimenti_prima_nota",
      tableRef: "modalDeferredTable",
      args: {},
      fields: [
        {
          key: "treasury_description",
          label: "Conto",
        },
        {
          key: "cash_time",
          label: "Data Recupero",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "cash_reference_description",
          label: "Causale",
        },
        {
          key: "related_description",
          label: "Descrizione",
        },
        {
          key: "gross",
          label: this.getDictionary("Importo", "treasury"),
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getTreasuries: "allTreasuries",
    }),
    initFilter() {
      let init = {
        byCashEntry: {
          id: this.resourceId,
        },
        byDeferred: "both",
      };
      return init;
    },
    onEdit(id, item) {
      this.initial_gross = item.gross;
      this.item = JSON.parse(JSON.stringify(item));
      this.$bvModal.show("editDeferredCashEntryModal");
    },
    fetchParent() {
      let criteria = this.filter;
      let name = this.filterName;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].fetch().then(() => {
        this.$emit("fetch");
        this.removeFilterByName(name);
      });
    },
    onDestroy(id) {
      console.log(id);
    },
    onUpdateEntryDetail(form) {
      // form.gross ==> il valore nuovo
      // this.parent_details.recupero ==> quello che è stato recuperato finora sul sospeso
      // this.parent_details.importo ==> importo totale del sospeso
      if (
        form.gross > 0 &&
        // form.gross <= this.parent_details.importo &&
        // (this.parent_details.status === "PARZIALMENTE APERTO" ||
        //   this.parent_details.status === "CHIUSO") &&
        form.gross <=
          this.parent_details.importo -
            (this.parent_details.recupero - Math.abs(parseFloat(form.importo)))
      ) {
        const payload = {
          importo: Math.abs(form.gross),
          id_conto_prima_nota: form.id_conto_prima_nota,
        };

        const Repo = RepositoryFactory.get(this.repository);
        Repo.aggiornaRecupero(this.resourceId, form.id, payload)
          .then(() => {
            let criteria = this.filter;
            let name = this.filterName;
            this.saveFilterByName({ name, criteria });
            this.$refs[this.tableRef].fetch().then(() => {
              this.$emit("fetch");
              this.parentFetch();
              this.$showSnackbar({
                preset: "success",
                text: `Sospeso Modificato con successo`,
              });
              this.removeFilterByName(name);
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: "L'importo inserito supera il residuo disponibile",
        });
      }
    },
    getCurrentCashReferences(value) {
      const cashReferences = this.getCashReferences();
      const found = cashReferences.find(
        (cash_reference) => cash_reference.value == value.id_causale_prima_nota
      );
      console.log(found);
      return `${found?.text} - ${found?.description}`;
    },
    getCurrentTreasury(value) {
      const treasuries = this.getTreasuries();
      const found = treasuries.find(
        (treasury) => treasury?.value == value.treasury_id
      );
      return found.code;
    },
    deferredMapping(data) {
      this.detail = data[0];
      let details = data;

      details.forEach((detail) => {
        detail.gross = Math.abs(detail.importo);
        detail.related_description = detail.descrizione;
        detail.treasury_description = detail.conto.codice;
        detail.cash_reference_description = `${detail.causale.codice} - ${detail.causale.descrizione}`;
        detail.cash_time = detail.data_registrazione;
      });

      return details;
    },
    parentFetch() {
      if (!this.repository || !this.resourceId) return;
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.sospeso(this.resourceId).then((response) => {
        const data = response.data;
        this.parent_details = data;
        this.parent_details.importo = parseFloat(data.importo);
        this.parent_details.recupero = data.recupero
          ? parseFloat(data.recupero)
          : 0;
        this.parent_details.status = !this.parent_details?.recupero
          ? "APERTO"
          : this.parent_details.recupero < this.parent_details.importo
          ? "PARZIALMENTE APERTO"
          : "CHIUSO";
      });
    },
  },
  beforeMount() {
    this.args = {
      items: { handle: this.deferredMapping },
      verb: {
        name: "recuperoSospesi",
        alias: "index",
        params: [this.resourceId],
      },
    };
  },
  mounted() {
    this.parentFetch();
  },
  components: {
    BaseIcon,
    EditDeferredCashEntryModal,
    DeferredCashEntries: () =>
      import("@/components/tables/ReducedDeferredCashEntry"),
  },
  computed: {
    book_entry() {
      return "book_entry";
    },
  },
};
</script>
