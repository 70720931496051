<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="salesmen"
                label="Produttore"
                :options="getSalesmen()"
                v-model="filter.produttore"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="deposit"
                label="Tipologia"
                :options="deposit"
                v-model="filter.stato"
                @select="onSelectDepositedType"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Descrizione"
                v-model="filter.descrizione"
                placeholder="Inserisci una descrizione"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <base-datepicker
                name="created_at"
                label="Data Apertura dal"
                v-model="filter.data_registrazione_inizio"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="created_at"
                label="Data Apertura al"
                v-model="filter.data_registrazione_fine"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data Recupero dal"
                v-model="filter.data_recupero_inizio"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data Recupero al"
                v-model="filter.data_recupero_fine"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca
              </b-button>
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXCOGIAC',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <deposited-entry-details-table
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        tipo: 'acconto',
        byRelations: ['byBroker', 'byTreasury', 'byCashReference'],
      }"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy', 'infomodal']"
    ></deposited-entry-details-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import DepositedEntryDetailsTable from "@/components/tables/DepositedEntryDetails.vue";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import ExportOptions from "@/components/ExportOptions";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      exportUrl: null,
      repository: "cash_entry",
      resource: "movimenti_prima_nota",
      tableRef: "CashEntriesDepositedTableRef",
      filterName: "filterCashEntriesDeposited",
      filter: this.initFilter(),
      lockedDates: false,
      deposit: [
        { value: "both", text: "Tutti" },
        { value: "yes", text: "Aperti" },
        { value: "no", text: "Chiusi e parzialmente incassati" },
      ],
      fields: [
        {
          key: "conto.codice",
          label: this.getDictionary("Conto"),
          sortable: true,
          sortKey: "byAttribute.conto",
        },
        {
          key: "data_registrazione",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("cash_time", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.data_registrazione",
        },
        {
          key: "recuperi",
          formatter: (value) => this.getLastCashEntry(value),
          label: "Data ultimo recupero",
          sortable: true,
          sortKey: "byAttribute.data_recupero",
        },
        {
          key: "descrizione",
          label: this.getDictionary("description", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.descrizione",
        },
        {
          key: "causale",
          label: "Causale",
          formatter: (value) => `${value.codice} - ${value.descrizione}`,
          sortable: true,
          sortKey: "byAttribute.causale",
        },
        {
          key: "produttore.formatted_title",
          formatter: (value) => (value ? value : "-"),
          label: this.getDictionary("broker_id", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.produttore",
        },
        {
          key: "importo",
          formatter: (value) => toLocaleCurrency(Math.abs(parseFloat(value))),
          label: this.getDictionary("Importo"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          sortable: true,
          sortKey: "byAttribute.importo",
        },
        {
          key: "recupero",
          formatter: (value) => toLocaleCurrency(Math.abs(parseFloat(value))),
          label: "Incassato",
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          sortable: true,
          sortKey: "byAttribute.incassato",
        },
        {
          key: "residuo",
          formatter: (value, field, item) =>
            toLocaleCurrency(
              item.importo - (item.recupero ? item.recupero : 0)
            ),
          label: this.getDictionary("Residuo"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  components: {
    DepositedEntryDetailsTable,
    BaseDatepicker,
    BaseInput,
    BaseSelect,
    ExportOptions
  },
  methods: {
    onSelectDepositedType(selected) {
      switch (selected) {
        case "both":
          this.lockedDates = true;
          break;
        default:
          this.lockedDates = false;
          break;
      }
      this.visible = true;
    },
    getLastCashEntry(value) {
      if (!value || !value.length) return "-";
      const lastCashEntry = value.at(-1);
      if (!lastCashEntry || !lastCashEntry.data_registrazione) return "-";
      return toLocaleDate(lastCashEntry.data_registrazione);
    },
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
          created_at: null,
          cash_time: null,
        },
        byCashReference: {
          reference_type: [1, 3],
        },
        byBroker: {
          id: null,
        },
        byDeposited: "yes",
        byCashTime: {
          to: null,
          from: null,
        },
        byCashHistory: {
          to: null,
          from: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit2`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create2` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.cancellaAcconto(id)
        .then(() => {
          this.$refs[this.tableRef].sortBy = "data_registrazione";
          this.$refs[this.tableRef].sortAsc = true;
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Acconto eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      if (this.filter.byDeposited) {
        let criteria = this.filter;
        this.saveFilterByName({ name, criteria });
        this.exportUrl = this.$refs[this.tableRef].loadFilter(
          this.$refs[this.tableRef].filterName
        );
        this.removePaginationByName(name);
        this.$refs[this.tableRef].currentPage = 1;
        this.$refs[this.tableRef].fetch();
      } else {
        this.$showSnackbar({
          preset: "success",
          text: "Il campo 'Tipologia' è obbligatorio",
        });
      }
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
